<template>
  <div class="rank">
    <div class="banner">
      <div class="banner-text">
        <p class="title animate__animated animate__fadeInLeftBig">中知智库
          <!-- <ul>
          <li>中国式现代化引领大科创时代，知识产权不仅是重要的资产要素，也是构建科技-产业-金融良性循环的关键抓手。</li>
          <li>中知智库研究院，中知数通旗下负责深入研究经济形势、营商环境、产业生态的专门机构，联合各界专家与研究机<br />
            构，聚焦前沿课题，以数据要素服务金融生态，以创新的方式赋能“创新”。</li>
        </ul> -->
        </p>
        <p class="subTitle animate__animated animate__fadeInRightBig">
        <ul>
          <li>中国式现代化引领大科创时代，知识产权不仅是重要的资产要素，也是构建科技-产业-金融良性循环的关键抓手。</li>
          <li>中知智库研究院，中知数通旗下负责深入研究经济形势、营商环境、产业生态的专门机构，联合各界专家与研究机<br />
            构，聚焦前沿课题，以数据要素服务金融生态，以创新的方式赋能“创新”。</li>
        </ul>
        </p>
      </div>
    </div>
    <section>
      <!-- <div class="china-tec">
        <div class="china-tec-wrap">
          <div class="title">中国企业科技力排行榜</div>
          <p class="title-desc">中国企业科技力排行榜”由中国技术市场协会、国家知识产权局知识产权研究会、新华社《经济参考报》、国家知识产权局知识产权出版社、中知数通（北京）信息技术有限公司联合发布。</p>
          <p class="title-desc">“中国企业科技力排行榜”以国家知识产权局知识产权大数据为基础，把每个企业的科技实力，放在全球竞争、国家战略、产业安全的角度来审视。由产业政策、市场热度、赛道排名，再到企业自身的科技实力、人才储备，逐层扫描，立体化地标定一个企业的产业地位，倡导企业树立面向未来、面向全球竞争的大科技观。</p>
          <p class="title-desc">“中国企业科技力排行榜”是中知数通“中国企业科技力评测模型”和“高价值科技企业挖掘识别模型”的重要应用案例。</p>
        </div>
      </div> -->
      <div class="rank animate__animated animate__fadeIn" style="background: #fff;">
        <div class="title">智库合作项目与成果</div>
        <div class="think-tank">
          <div class="achievements">
            <div class="achievement">
              <ul>
                <li class="project">
                  <p class="item1"><span>项目 1：</span>联合中国技术市场协会、国家知识产权局知识产权研究会、新华社<br />
                    《经济参考报》、国家知识产权局知识产权出版社发起中国企业科技力量<br />
                    化评测联合课题组<br />
                  </p>
                  <p class="item2"><span>成果：</span>《中国企业科技力排行榜》</p>
                </li>
              </ul>
            </div>
            <div class="achievement">
              <ul>
                <li class="project">
                  <p class="item1">
                    <span>项目 2：</span>联合知识产权出版社申报国家知识产权局软科学研究项目
                  </p>
                  <p class="item2"><span>成果：</span>《基于知识产权数据的企业科创能力评价研究》</p>
                </li>
              </ul>
            </div>
            <div class="achievement">
              <ul>
                <li class="project">
                  <p class="item1">
                    <span>项目 3：</span>中知数通-同济大学 智慧科技研究院
                  </p>
                  <p class="item2"><span>成果：</span>《智能产业图谱机器学习方法与实践》</p>
                </li>
              </ul>
            </div>
          </div>
          <div class="img-container">
            <img src="https://images.zzt.com.cn/ks/2023/04/25/thinkTank.png" alt="">
          </div>
        </div>
      </div>
      <div class="rank">
        <div class="title">往期排行榜</div>
        <div class="rank-wrap">
          <div class="rank-box" v-for="rank in rankList" :key="rank.title" @click="toLink(rank.url)">
            <img :src="rank.img" :alt="rank.title" width="250px">
            <div class="rank-item">
              <div>
                <p class="rank-item__title">{{rank.title}}</p>
                <p class="rank-item__desc">{{rank.content}}</p>
              </div>
              <p class="rank-item__bottom">
                <span>{{rank.time}}</span>
                <span>{{rank.origin}}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: 'ThinkTanks',
  data() {
    return {
      rankList: [
        {
          img: 'https://images.zzt.com.cn/ks/2023/06/19/rank3.png',
          title: '2023网络安全企业科技能力报告',
          content: '中知智库与安全419作为主要支撑研究单位，联合中关村网络安全与信息化产业联盟发布《2023网络安全企业科技能力报告》，发现有效专利超八成为发明专利，且总估值超亿元；近五年新增公开专利数量连年上涨；专利聚焦于数字信息传输、电数字数据处理。',
          time: '2023-05-31',
          origin: '',
          url: 'https://mp.weixin.qq.com/s?__biz=Mzg2NjgxNTE5NA==&mid=2247484907&idx=1&sn=5fd75c2823cda2704cc2a2ccc233dcce&chksm=ce44585ef933d148359e989f348724718af54c0e05a6d98864e2730e2d9b889967bd361f1bcb&vid=1688854700419086&deviceid=1e5ce778-1918-447a-b62b-7c9e9314f5a0&version=4.1.6.6018&platform=win#rd'
        },
        {
          img: 'https://images.zzt.com.cn/ox/2022/12/26/car.png',
          title: '汽车芯片国产化不足，哪些企业将成为破局者？',
          content: '汽车芯片逐渐成为最重要的零部件之一。在“国产替代”成为当前国际科技竞争的主旋律背景下，国内企业正在从汽车芯片的各个领域逐个击破。从长远来看，在汽车芯片技术研发、车规级认证和量产方面取得进展后，国内企业有机会实现全方面打破国外垄断的愿景。',
          time: '2022-12-08',
          origin: '',
          url: 'https://mp.weixin.qq.com/s/hqva491jh13U5MVWh7q5nA'
        },
        {
          img: 'https://images.zzt.com.cn/ox/2022/11/03/rank22.png',
          title: '构筑我国能源安全的“主动脉”，这些特高压领域企业榜上有名！',
          content: '中国作为世界第一能源消耗大国，守护能源安全任重道远。因为特高压技术的从无到有，从跟随到引领，我国一步一步的构筑起了纵横交错，横贯东西的能源输送“主动脉”。在全球能源安全异常重要的当下，更显弥足珍贵。',
          time: '2022-10-27',
          origin: '',
          url: 'https://mp.weixin.qq.com/s?__biz=Mzg2NjgxNTE5NA==&mid=2247484424&idx=1&sn=239873fb03815cc4374332c1b32eb00b&chksm=ce4459bdf933d0ab0bfee00763d9ca2bc73cbaf61eb69145bde2fed817f64c1600935cbd9b78#rd'
        },
        {
          img: 'https://images.zzt.com.cn/ks/2023/06/06/rank1.png',
          title: '新能源电池企业专利指数排行榜发布，产业格局仍有短板尚未突破！',
          content: '新能源电池企业专利指数排行榜发布，比亚迪超过“宁王”，成“榜上一哥”，但产业格局仍有短板尚未突破！',
          time: '2022-09-22',
          origin: '',
          url: 'https://mp.weixin.qq.com/s/Pl3TYfqqq-i0AUGp_flQ1w'
        },
      ]
    }
  },
  methods: {
    toLink(url) {
      window.open(url, { target: '_blank' })
    }
  }
}
</script>

<style lang="scss" scoped>
.banner {
  width: 100%;
  height: Translate(500px);
  background: url('https://images.zzt.com.cn/ks/2023/04/25/thinkTank-banner.png')
    no-repeat;
  background-position: center center;
  background-size: cover;
  &-text {
    width: $container-width;
    margin: 0 auto;
    position: relative;
    p {
      margin: 0;
      text-align: left;
    }
    .title,
    .subTitle {
      ul {
        padding: 40px;
        padding-left: 0;
        font-size: Translate(16px);
        letter-spacing: 0;
        line-height: Translate(40px);
        font-weight: 400;
        position: relative;
        top: Translate(30px);
        li {
          list-style: none;
        }
      }
      position: absolute;
      top: Translate(144px);
      left: 0;
      font-family: 'PingFangSC-Medium';
      font-size: Translate(40px);
      color: #ffffff;
      font-weight: 500;
      letter-spacing: 1px;
      // &::after {
      //   position: absolute;
      //   bottom: -60px;
      //   left: 0;
      //   display: inline-block;
      //   content: "";
      //   width: 136px;
      //   height: Translate(4px);
      //   background-color: rgba($color: #fff, $alpha: .7);
      // }
    }
  }
}
section {
  .title {
    font-size: Translate(36px);
    font-weight: 500;
    margin-bottom: Translate(40px);
  }
  .think-tank {
    width: $container-width;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .img-container {
      margin-top: Translate(30px);
      display: flex;
      justify-content: center;
      align-content: center;
      img {
        height: Translate(350px);
      }
    }
  }
  .china-tec {
    background-color: #fff;
    .china-tec-wrap {
      width: $container-width;
      margin: 0 auto;
      padding: Translate(70px) 0 Translate(84px);
    }
    .title-desc {
      font-size: Translate(16px);
      line-height: Translate(30px);
      text-align: left;
      margin-bottom: Translate(22px);
    }
  }
  .rank {
    padding: Translate(74px);
    .achievement {
      .project {
        margin-bottom: Translate(44px);
        .item1,
        .item2 {
          span {
            font-size: Translate(18px);
            font-weight: 500;
          }
          text-align: start;
          font-size: Translate(16px);
          color: #333333;
          line-height: Translate(30px);
          font-weight: 400;
        }
        .item2 {
          color: #4088eb;
          margin-top: Translate(8px);
        }
      }
      ul {
        padding: 0;
      }
    }
    .rank-wrap {
      width: $container-width;
      margin: 0 auto;
    }
  }
  .rank-box {
    cursor: pointer;
    img {
      margin-right: 66px;
    }
    display: flex;
    margin-bottom: 94px;
    .rank-item {
      p {
        margin: 24px 0;
      }
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: left;
      &__title {
        font-family: 'PingFangSC-Semibold';
        font-size: 46px;
        color: #333333;
        line-height: 52px;
        font-weight: 600;
      }
      &__desc {
        font-family: 'PingFangSC-Regular';
        font-size: 34px;
        color: #888;
        line-height: 56px;
        font-weight: 400;
      }
      &__bottom {
        font-family: 'PingFangSC-Regular';
        font-size: 28px;
        color: #666;
        line-height: 28px;
        font-weight: 400;
        span {
          display: inline-block;
          margin-right: 46px;
        }
      }
    }
  }
}
</style>
